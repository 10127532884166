import featureTooling from 'components/FeatureTooling/FeatureTooling';
import VideoModel from 'models/video.model';
import { AVAILABLE_REGIONS, FEATURE_SLUG, ICONS, PLAYER_MESSAGES } from '../../client/constants';

const getVideoErrorDetails = (
  errorCode: number,
  playlistItem: VideoModel
): {
  heading: string;
  body: string;
  icon: string | null;
} | null => {
  const userInNL = featureTooling.user?.attributes.countryCode === AVAILABLE_REGIONS.NETHERLANDS;
  if (!userInNL && playlistItem.availableRegion === AVAILABLE_REGIONS.NETHERLANDS) {
    return PLAYER_MESSAGES.GEO_BLOCKED;
  }

  const playerErrorMessages = featureTooling.getFeatureVariables<{ default: string; [key: string]: string }>(
    FEATURE_SLUG.PLAYER_ERROR_MESSAGES
  );

  if (!playerErrorMessages) return null;

  return {
    heading: playerErrorMessages[errorCode] || playerErrorMessages.default,
    body: `Error code: ${errorCode}`,
    icon: ICONS.ALERT
  };
};

export default getVideoErrorDetails;
