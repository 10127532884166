import { gql } from '@apollo/client';

const PlaylistControlsQuery = gql`
  query playlistControlsQuery(
    $tvSeasonId: String
    $programTypes: [ProgramType]
    $seriesId: String
    $skip: Int
    $limit: Int
    $sort: ProgramSortKey
  ) {
    programs(
      tvSeasonId: $tvSeasonId
      programTypes: $programTypes
      seriesId: $seriesId
      skip: $skip
      limit: $limit
      sort: $sort
      availableWithinHours: 0
    ) {
      items {
        guid
        tvSeasonEpisodeNumber
        tvSeasonId
        series {
          slug
        }
      }
    }
  }
`;

export default PlaylistControlsQuery;
