import styled from 'styled-components';
import { ButtonStyle } from 'components/Button/Button.style';
import { Heading1, BodyText } from '../../Text/Text.style';

export const VideoUnavailableContainerStyle = styled.div`
  background-color: #222222;
  top: 0;
  left: 0;
  padding: 16px;
  width: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: center;
  justify-content: center;
  -webkit-box-pack: center;
  svg {
    width: 70px;
    height: 70px;
    margin: 0 auto 30px;
    object-fit: contain;
    display: none;
  }
  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    svg {
      display: block;
    }
  }
`;

export const VideoUnavailableMessageStyle = styled.div<{ hasLargeHeading?: boolean }>`
  max-width: 640px;

  ${({ hasLargeHeading }) =>
    !hasLargeHeading &&
    `${Heading1} {
    font-size: 24px;
    font-weight: normal;
    line-height: 1.2;
  }`}

  ${Heading1}, ${BodyText} {
    text-align: center;
    margin-bottom: 16px;
  }

  ${ButtonStyle} {
    width: 150px;
    margin: 16px auto;
  }

  @media (max-width: ${props => props.theme.breakpoints.mdMin}) {
    ${Heading1} {
      margin-bottom: 8px;
    }
  }
`;
