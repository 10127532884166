export default class VideoCollectionModel {
  items: {
    guid: string;
    path: string;
    series: { slug: string };
  }[] = [];

  constructor(collection: IVideoCollectionQueryResult['collection']) {
    if (collection) {
      const queryKey = collection.onEditorialTabs ? 'tab' : 'afspeellijst';
      this.items = collection.programs.map(({ guid, series }) => ({
        guid,
        path: `/programmas/${series?.slug}/${guid}?${queryKey}=${collection.slug}`,
        series
      }));
    }
  }
}
