import { FunctionComponent } from 'react';
import { Grid, GridElement } from '../../styles/grid';
import {
  PromoErrorHeading,
  PromoFallbackStyle,
  PromoImageHeadingFallbackStyle
} from './PromoImageHeadingFallback.style';

const PromoImageHeadingFallback: FunctionComponent = () => (
  <PromoImageHeadingFallbackStyle hasImage={false}>
    <PromoFallbackStyle data-testid="promoHeadingFallback">
      <Grid>
        <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
          <PromoErrorHeading data-testid="boundaryErrorPromoHeading">
            Oeps, er ging iets fout. Kan je het later nog eens proberen?
          </PromoErrorHeading>
        </GridElement>
      </Grid>
    </PromoFallbackStyle>
  </PromoImageHeadingFallbackStyle>
);

export default PromoImageHeadingFallback;
