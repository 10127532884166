import { gql } from '@apollo/client';

const PlaylistCollectionQuery = gql`
  query collection($slug: String) {
    collection(slug: $slug) {
      onEditorialTabs
      slug
      programs {
        guid
        series {
          slug
        }
      }
    }
  }
`;
export default PlaylistCollectionQuery;
