import styled from 'styled-components';
import { PromoImageHeadingStyle } from './PromoImageHeading.style';

export const PromoErrorHeading = styled.div`
  display: none;
  color: #fff;
`;
export const PromoFallbackStyle = styled.div`
  position: absolute;
  top: 200px;
`;

export const PromoImageHeadingFallbackStyle = styled(PromoImageHeadingStyle)`
  height: 0;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    height: 120px;
  }
`;
