import styled from 'styled-components';
import { ICONS } from '../../../constants/icons';
import { WideScreenDivStyle } from '../PlayerContainer.style';

export const VideoPlaceholderContainerStyle = styled(WideScreenDivStyle)<{ backgroundUrl: string }>`
  background-color: #222222;
  background-image: ${props => props.backgroundUrl};
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 16px;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  -webkit-box-pack: center;
  cursor: pointer;
  &:focus {
    border: 2px solid #4d90fe;
  }
  &:active {
    border-color: transparent;
  }
`;

export const VideoPlaceholderPlayIconStyle = styled.div`
  width: 80px;
  height: 80px;
  background-image: url(${ICONS.PLAY_FILL_LARGE});
  background-size: contain;
  background-repeat: no-repeat;
  &:focus {
    border: 2px solid #4d90fe;
  }
  &:active {
    border-color: transparent;
  }
`;
