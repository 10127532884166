import { FunctionComponent } from 'react';
import SVG from 'react-inlinesvg';
import { IVideoMetaDataLayoutProps } from 'typings/IVideoMetaDataLayoutProps';
import Bookmark from '../../Bookmark';
import { ActionLabel } from '../../Text/Text.style';
import {
  VideoMetaDataTitleStyle,
  VideoMetaDataSubtextStyle,
  VideoMetaDataLabelStyle,
  VideoDescriptionWrapperStyle,
  VideoMetaDataDescriptionStyle,
  VideoMetaDataArrowStyle,
  VideoDescriptionBottomSeparatorStyle,
  VideoMetaDataSubtextWrapperStyle,
  VideoMetaDataBookmarkWrapperStyle,
  VideoMetaDataTimeStyle
} from './VideoMetaDataLayout.style';
import Ratings from '../../Ratings';
import { PROGRAM } from '../../../constants/program';
import { ICONS } from '../../../constants/icons';
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from '../../../constants';

const VideoMetaDataLayout: FunctionComponent<IVideoMetaDataLayoutProps> = props => {
  const { seriesTitle, title, ratings, guid, series, longDescription, description, type, subtitle, metadata } =
    props.video;

  return (
    <>
      <VideoMetaDataTitleStyle data-testid="videoMetaDataTitle" isCollapsed={props.isCollapsed}>
        {seriesTitle || title}
      </VideoMetaDataTitleStyle>
      {subtitle && (
        <VideoMetaDataSubtextStyle data-testid="videoMetaDataSubtextStyle">{subtitle}</VideoMetaDataSubtextStyle>
      )}
      <VideoMetaDataSubtextWrapperStyle data-testid="videoMetaDataSubtext">
        {props.label && (
          <VideoMetaDataLabelStyle>
            <ActionLabel isPrimary>{props.label}</ActionLabel>
          </VideoMetaDataLabelStyle>
        )}
        <VideoMetaDataTimeStyle data-testid="videoMetaDataVideoTime">{props.videoTime}</VideoMetaDataTimeStyle>
      </VideoMetaDataSubtextWrapperStyle>
      {ratings && ratings.length > 0 && <Ratings rating={ratings[0].rating} subRatings={ratings[0].subRatings} />}
      <VideoMetaDataBookmarkWrapperStyle>
        <Bookmark
          programGuid={type === PROGRAM.MOVIE ? guid : series?.guid}
          showSocialButtons
          clickEventValues={{
            category: TEALIUM_EVENT_CATEGORY.PLAYER_META,
            label: null,
            name: TEALIUM_EVENT_NAME.ADD_TO_FAVORITE,
            defaultValues: { ...metadata }
          }}
        />
      </VideoMetaDataBookmarkWrapperStyle>
      <VideoDescriptionWrapperStyle data-testid="videoDescriptionWrapperStyle" ref={props.toggleRef}>
        <VideoMetaDataDescriptionStyle data-testid="videoMetaDataDescriptionStyle">
          {longDescription || description || ''}
        </VideoMetaDataDescriptionStyle>
      </VideoDescriptionWrapperStyle>
      <VideoDescriptionBottomSeparatorStyle data-testid="videoDescriptionBottomSeparatorStyle">
        <VideoMetaDataArrowStyle
          onClick={props.toggleVideoDescription}
          data-testid="videoMetaDataArrow"
          ref={props.toggleArrowRef}
        >
          <SVG src={ICONS.ARROW_SMALL} />
        </VideoMetaDataArrowStyle>
      </VideoDescriptionBottomSeparatorStyle>
    </>
  );
};

export default VideoMetaDataLayout;
