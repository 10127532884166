import { ApolloClient } from '@apollo/client';
import { trackClick, trackView } from 'lib/tealium';
import PlaylistControlsQuery from 'queries/programs/playlistControlsQuery';
import { formatToMinutesSeconds } from 'lib/helpers';
import VideoModel from 'models/video.model';
import { IPlaylistControls } from 'typings/IPlaylistControls';
import ProgramsByGuidQuery from 'queries/programs/programsByGuidQuery.query';
import EpisodeModel from 'models/episode.program.model';
import ClipModel from 'models/clip.program.model';
import PlaylistCollectionQuery from 'queries/collection/playlistCollection.query';
import VideoCollectionModel from 'models/videoCollection.model';
import {
  CLOUDINARY,
  FIXED_COUNT,
  PROGRAM,
  PROGRAM_SORT_KEY,
  TEALIUM_EVENT_CATEGORY,
  TEALIUM_EVENT_LABEL,
  TEALIUM_EVENT_NAME
} from '../../../constants';

const MAX_ITEMS_TO_FETCH = 500;
const NEXT_UP = 'Next Up';
const EPISODE = 'Aflevering';
const SEASON = 'Seizoen';
const PREVIOUS_VIDEO = -1;
const NEXT_VIDEO = 1;
const MAX_ITEMS_TO_SKIP = 10;

type TMetadata = { [key: string]: string | boolean | number | null };

class PlaylistControls {
  client: ApolloClient<object>;
  jwplayer: jwplayer.JWPlayer;
  push: (path: string) => void;
  currentItem: VideoModel;
  collectionSlug?: string | string[];
  nextVideo: { guid: string; path: string; metadata?: TMetadata } | null = null;
  prevVideo: { guid: string; path: string; metadata?: TMetadata } | null = null;
  availableSeasons: ISeriesSeasonAvailability[] | undefined;
  nextUpCardClosed = false;
  nextUpCardShown = false;

  constructor({ client, playlistItem, collectionSlug, push, jwplayer, availableSeasons }: IPlaylistControls) {
    this.client = client;
    this.jwplayer = jwplayer;
    this.push = push;
    this.currentItem = playlistItem;
    this.collectionSlug = collectionSlug;
    this.availableSeasons = availableSeasons;

    this.fetchPlaylistItems().then(this.addControlButtons);
  }

  async fetchPlaylistItems(): Promise<Omit<IPlaylistVideoItem, 'tvSeasonEpisodeNumber'>[]> {
    const { guid, tvSeasonId, type, series } = this.currentItem;

    if (type === PROGRAM.MOVIE) return [];

    if (this.collectionSlug) {
      const result = await this.client.query<IVideoCollectionQueryResult>({
        query: PlaylistCollectionQuery,
        variables: { slug: this.collectionSlug }
      });
      const collection = new VideoCollectionModel(result.data?.collection);
      if (collection.items.length) {
        if (!collection.items.some(item => item.guid === guid)) {
          this.push(collection.items[0].path);
          return [];
        }
        return collection.items;
      }
      return [];
    }

    const videos: IPlaylistVideoItem[] = [];
    let itemsToSkip = 0;
    let moreItems = false;
    let nextItemFetched = false;
    let prevItemFetched = false;

    try {
      do {
        const {
          data: {
            programs: { items }
          }
        } = await this.client.query<{ programs: { items: IPlaylistVideoItem[] } }>({
          query: PlaylistControlsQuery,
          variables: {
            ...(type === PROGRAM.EPISODE
              ? { tvSeasonId }
              : { seriesId: series.id, sort: PROGRAM_SORT_KEY.PUBLICATIONDATETIME }),
            limit: FIXED_COUNT.TEMPORARY_FORMAT_LIMIT,
            programTypes: type,
            skip: itemsToSkip
          }
        });

        moreItems = !!items.length;
        videos.push(...items);
        itemsToSkip = videos.length;

        if (type === PROGRAM.EPISODE) videos.sort((a, b) => a.tvSeasonEpisodeNumber - b.tvSeasonEpisodeNumber);

        const currentIndex = videos.findIndex(item => guid === item.guid);

        nextItemFetched = currentIndex > -1 && !!videos[currentIndex + 1];
        prevItemFetched = currentIndex > -1 && !!videos[currentIndex - 1];
      } while (moreItems && itemsToSkip <= MAX_ITEMS_TO_FETCH && (!nextItemFetched || !prevItemFetched));
    } catch (ignore) {}

    if (!prevItemFetched || !nextItemFetched) {
      if (!this.availableSeasons) return videos;

      const currentSeason = this.availableSeasons.find(({ id, isDisabled }) => id === tvSeasonId && !isDisabled);
      if (!currentSeason) return videos;

      const sortedAvailableSeasons = this.availableSeasons.sort(
        (a, b) => Number(a?.seasonNumber) - Number(b?.seasonNumber)
      );

      const currentSeasonIndex = sortedAvailableSeasons.findIndex(season => season.id === currentSeason.id);

      if (videos.length === 1) {
        if (sortedAvailableSeasons[currentSeasonIndex + 1]) {
          const fetchedItem = await this.fetchItemFromOtherSeason(
            sortedAvailableSeasons[currentSeasonIndex + NEXT_VIDEO],
            NEXT_VIDEO
          );

          if (fetchedItem) videos.push(fetchedItem);
        }

        if (sortedAvailableSeasons[currentSeasonIndex - 1]) {
          const fetchedItem = await this.fetchItemFromOtherSeason(
            sortedAvailableSeasons[currentSeasonIndex + PREVIOUS_VIDEO],
            PREVIOUS_VIDEO
          );

          if (fetchedItem) videos.unshift(fetchedItem);
        }
      } else {
        const index = !prevItemFetched ? PREVIOUS_VIDEO : NEXT_VIDEO;
        const fetchedItem = await this.fetchItemFromOtherSeason(
          sortedAvailableSeasons[currentSeasonIndex + index],
          index
        );

        if (fetchedItem) !prevItemFetched ? videos.unshift(fetchedItem) : videos.push(fetchedItem);
      }
    }

    return videos;
  }

  async fetchItemFromOtherSeason(
    seasonToFetch: ISeriesSeasonAvailability,
    index: number
  ): Promise<IPlaylistVideoItem | null> {
    if (!seasonToFetch) return null;

    let itemsToSkip = 0;
    let moreItems = 0;
    try {
      do {
        const {
          data: {
            programs: { items }
          }
        } = await this.client.query<{ programs: { items: IPlaylistVideoItem[] } }>({
          query: PlaylistControlsQuery,
          variables: {
            tvSeasonId: seasonToFetch.id,
            programTypes: PROGRAM.EPISODE,
            availability: true,
            sort: index > 0 ? PROGRAM_SORT_KEY.EPISODENUMBER_ASC : PROGRAM_SORT_KEY.EPISODENUMBER_DESC,
            limit: 1,
            skip: itemsToSkip
          }
        });

        moreItems = items.length;
        itemsToSkip += 1;
        if (items.length) return items[0];
      } while (moreItems === 0 && itemsToSkip < MAX_ITEMS_TO_SKIP);
    } catch (ignore) {
      return null;
    }
    return null;
  }

  addControlButtons = (playlist: Omit<IPlaylistVideoItem, 'tvSeasonEpisodeNumber'>[]): void => {
    // cleanup
    document.querySelectorAll('.jw-playlist-control').forEach(element => element.remove());

    const currentIndex = playlist.findIndex(({ guid }) => guid === this.currentItem.guid);

    const queryParameters = this.collectionSlug
      ? `?${document.location.search.includes('afspeellijst') ? 'afspeellijst' : 'tab'}=${this.collectionSlug}`
      : '';

    const nextItem = currentIndex > -1 && playlist[currentIndex + 1];
    const prevItem = currentIndex > -1 && playlist[currentIndex - 1];

    if (nextItem) {
      this.nextVideo = {
        guid: nextItem.guid,
        path: `/programmas/${nextItem.series.slug}/${nextItem.guid}${queryParameters}`
      };
    }

    if (prevItem) {
      this.prevVideo = {
        guid: prevItem.guid,
        path: `/programmas/${prevItem.series.slug}/${prevItem.guid}${queryParameters}`
      };
    }

    const playerContainer = this.jwplayer.getContainer();
    const disabledClassName = 'talpa-jw-disabled';

    if (!playerContainer || this.currentItem.type === PROGRAM.MOVIE) return;

    const prevButton = document.createElement('div');
    prevButton.className = 'jw-playlist-control jw-display-icon-container jw-reset talpa-jw-display-icon-prev';
    if (!this.prevVideo) prevButton.classList.add(disabledClassName);
    prevButton.innerHTML = `
      <div class="jw-icon jw-button-color jw-reset" role="button" tabIndex="0" aria-label="Previous">
        <svg class="jw-svg-icon talpa-jw-svg-icon-prev" viewBox="0 0 240 240" focusable="false"/>
      </div>
    `;
    prevButton.onclick = prevItem ? this.playPreviousItem : null;

    playerContainer.querySelector('.jw-display-controls')?.prepend(prevButton);

    const nextButton = document.createElement('div');
    nextButton.className = 'jw-playlist-control jw-display-icon-container jw-reset talpa-jw-display-icon-next';
    if (!this.nextVideo) nextButton.classList.add(disabledClassName);

    nextButton.innerHTML = `
      <div class="jw-icon jw-button-color jw-reset" role="button" tabIndex="0" aria-label="Next">
        <svg class="jw-svg-icon talpa-jw-svg-icon-next" viewBox="0 0 240 240" focusable="false"/>
      </div>
    `;
    nextButton.onclick = nextButton ? this.playNextItem : null;

    playerContainer.querySelector('.jw-display-controls')?.append(nextButton);
  };

  async addNextUpCard(): Promise<void> {
    if (this.nextUpCardShown || this.nextUpCardClosed || !this.nextVideo) return;

    const container = this.jwplayer.getContainer();
    if (!container) return;

    try {
      const { data } = await this.client.query<{ programs: { items: IProgramGraphql[] } }>({
        query: ProgramsByGuidQuery,
        variables: { guid: this.nextVideo.guid }
      });

      const item = data.programs.items[0];
      if (!item) return;

      const nextUpVideo = item.type === PROGRAM.CLIP ? new ClipModel(item) : new EpisodeModel(item);
      this.nextVideo.metadata = nextUpVideo.metadata;

      document.querySelectorAll('.next-up-card').forEach(element => element.remove());
      const fullEpisodeButton = document.querySelector('.full-episode-button') as HTMLElement | null;
      if (fullEpisodeButton) {
        fullEpisodeButton.style.display = 'none';
      }
      const nextUpCard = document.createElement('div');

      const nextUpPicture = document.createElement('div');
      nextUpPicture.className = 'next-up-card-picture';
      nextUpPicture.style.backgroundImage = `url('${CLOUDINARY.BASE_PATH}fetch/ar_16:9,c_fill,dpr_2.0,f_auto,g_face:auto,h_235,w_auto,f_auto/${nextUpVideo.imageLandscape.src}')`;

      const nextUpTextWrapper = document.createElement('div');
      nextUpTextWrapper.className = 'next-up-text-wrapper';

      const nextUpTitle = document.createElement('div');
      nextUpTitle.innerHTML = NEXT_UP;
      nextUpTitle.className = 'next-up-title';

      const nextUpSubtitle = document.createElement('div');
      nextUpSubtitle.innerHTML =
        nextUpVideo.type === PROGRAM.EPISODE
          ? `${EPISODE} ${nextUpVideo.tvSeasonEpisodeNumber}, ${SEASON} ${nextUpVideo.seasonNumber}`
          : `${nextUpVideo.title}`;
      nextUpSubtitle.className = 'next-up-subtitle';

      const episodeLength = document.createElement('div');
      episodeLength.innerHTML = formatToMinutesSeconds(nextUpVideo.duration);
      episodeLength.className = 'next-up-video-length';

      nextUpTextWrapper.append(nextUpTitle, nextUpSubtitle, episodeLength);

      const closeButton = document.createElement('button');
      closeButton.className = 'next-up-close-button';
      closeButton.onclick = e => {
        e.stopPropagation();
        nextUpCard.remove();
        this.nextUpCardClosed = true;
      };

      nextUpCard.className = 'next-up-card';
      nextUpCard.onclick = () => {
        if (this.nextVideo) {
          this.push(this.nextVideo.path);
          trackClick({
            name: TEALIUM_EVENT_NAME.PLAYER_CLICK,
            category: TEALIUM_EVENT_CATEGORY.PLAYER,
            label: TEALIUM_EVENT_LABEL.SUGGESTED_CONTENT,
            defaultValues: this.nextVideo.metadata
          });
        }
      };

      nextUpCard.append(nextUpPicture, nextUpTextWrapper, closeButton);

      container.appendChild(nextUpCard);
      this.nextUpCardShown = true;
      trackView({
        event_name: TEALIUM_EVENT_NAME.PLAYER_VIEW,
        event_category: TEALIUM_EVENT_CATEGORY.PLAYER,
        event_label: TEALIUM_EVENT_LABEL.SUGGESTED_CONTENT,
        ...this.nextVideo.metadata
      });
    } catch (ignore) {}
  }

  removeNextUpCard = () => {
    if (this.nextUpCardShown) {
      document.querySelector('.next-up-card')?.remove();
      const fullEpisodeButton = document.querySelector('.full-episode-button') as HTMLElement | null;
      if (fullEpisodeButton) {
        fullEpisodeButton.style.display = 'flex';
      }
      this.nextUpCardShown = false;
    }
  };

  playNextItem = (): void => {
    if (this.nextVideo) {
      this.push(this.nextVideo.path);
      trackClick({
        category: TEALIUM_EVENT_CATEGORY.PLAYER,
        name: TEALIUM_EVENT_NAME.PLAYER_NEXT,
        label: null
      });
    }
  };

  autoplayNextItem = () => {
    if (this.nextVideo) {
      this.push(this.nextVideo.path);
      trackClick({
        name: TEALIUM_EVENT_NAME.PLAYER_AUTOPLAY,
        category: TEALIUM_EVENT_CATEGORY.PLAYER,
        label: TEALIUM_EVENT_LABEL.SUGGESTED_CONTENT,
        defaultValues: this.nextVideo.metadata
      });
    }
  };

  playPreviousItem = (): void => {
    if (this.prevVideo) {
      this.push(this.prevVideo.path);
      trackClick({
        category: TEALIUM_EVENT_CATEGORY.PLAYER,
        name: TEALIUM_EVENT_NAME.PLAYER_PREVIOUS,
        label: null
      });
    }
  };
}

export default PlaylistControls;
