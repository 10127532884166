import styled from 'styled-components';
import { BodyText, Heading1 } from 'components/Text/Text.style';
import { ButtonStyle } from 'components/Button/Button.style';
import Colors from '../../../styles/colors';
import { WideScreenDivStyle } from '../PlayerContainer.style';

const CIRCLE_DIAMETER_DESKTOP = '80px';
const CIRCLE_DIAMETER_MOBILE = '48px';

export const VideoAdBlockerContainerStyle = styled(WideScreenDivStyle)`
  background-color: ${Colors.nero};
  padding: 16px;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
`;

export const VideoAdBlockerMessageStyle = styled.div`
  max-width: 640px;
  display: grid;
  justify-items: center;
  gap: 8px;
  @media (min-width: ${props => props.theme.breakpoints.smMid}) {
    gap: 16px;
  }

  ${Heading1} {
    text-align: center;
    margin: 0;
    font-size: 20px;
    @media (min-width: ${props => props.theme.breakpoints.smMid}) {
      font-size: 24px;
    }
  }

  ${BodyText} {
    text-align: center;
    margin-bottom: -8px;
    @media (min-width: ${props => props.theme.breakpoints.smMid}) {
      margin-bottom: 0;
    }
  }

  ${ButtonStyle} {
    display: none;
    width: fit-content;
    height: 48px;
    background-color: ${Colors.greenishTeal};
    border: none;
    @media (min-width: ${props => props.theme.breakpoints.smMid}) {
      display: block;
    }
  }
`;

export const VideoAdBlockerIconStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VideoAdBlockerInnerIconStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${CIRCLE_DIAMETER_MOBILE};
  height: ${CIRCLE_DIAMETER_MOBILE};
  font-size: 35px;
  font-weight: normal;
  font-style: normal;
  color: ${Colors.white};
  border: solid 3px ${Colors.white};
  border-radius: ${CIRCLE_DIAMETER_MOBILE};

  @media (min-width: ${props => props.theme.breakpoints.smMid}) {
    font-size: 54px;
    width: ${CIRCLE_DIAMETER_DESKTOP};
    height: ${CIRCLE_DIAMETER_DESKTOP};
    border-radius: ${CIRCLE_DIAMETER_DESKTOP};
  }
`;
