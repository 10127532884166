import React, { FunctionComponent, useState } from 'react';
import { useQuery } from '@apollo/client';
import { getSocialDate, getPlayerWidth, shuffleArray } from 'lib/helpers';
import { IVideoHeadingLayoutProps } from 'typings/IVideoHeadingLayoutProps';
import useFeature from 'hooks/useFeature';
import PlayerSidebar from 'components/PlayerSidebar';
import { useScreenDimensions } from 'lib/useScreenDimensions';
import CollectionQuery from 'queries/collection/collection.query';
import VideoModel from 'models/video.model';
import ClipModel from 'models/clip.program.model';
import EpisodeModel from 'models/episode.program.model';
import { SkeletonVideoHeading } from 'components/SkeletonElement/SkeletonElement';
import ErrorBoundary from '../../ErrorBoundary';
import VideoMetaData from '../../VideoMetaData';
import PlayerContainer from '../../PlayerContainer';
import CommonFallback from '../../CommonFallback';
import {
  VideoHeadingStyle,
  VideoHeadingInnerStyle,
  PlayerWrapperStyle,
  VideoHeadingSeparatorStyle,
  VideoMetaDataWrapperStyle,
  VideoHeadingImageInnerStyle
} from './VideoHeadingLayout.style';
import {
  IMAGE_MEDIA_TYPE,
  FALLBACK_UI_LABELS,
  FEATURE_SLUG,
  IS_CLIENT,
  PROGRAM,
  TEALIUM_EVENT_CATEGORY,
  TEALIUM_EVENT_NAME
} from '../../../constants';
import ImageMedia from '../../ImageMedia/ImageMedia';
import { TextLabelStyle } from '../../VideoItem/VideoItemDescription/VideoItemDescription.style';

const VideoHeadingLayout: FunctionComponent<IVideoHeadingLayoutProps> = ({
  availabilityState,
  client,
  playlistItem,
  viewMode,
  pageType,
  collectionSlug,
  availableSeasons
}) => {
  const [sideBarVideos, setSideBarVideos] = useState<VideoModel[]>([]);

  const { enabled, variables: sidebarVariables } = useFeature<{ default: string; [key: string]: string }>(
    FEATURE_SLUG.PLAYER_SIDEBAR
  );
  const sidebarSlug = sidebarVariables?.[playlistItem.series?.slug] || sidebarVariables?.default;

  const { loading: loadingSidebar, data } = useQuery<ICollectionQueryResult>(CollectionQuery, {
    variables: { slug: sidebarSlug },
    skip: !sidebarSlug,
    onCompleted: ({ collection }) => {
      if (!collection) return;

      const items = shuffleArray<IProgramGraphql>(collection.programs)
        .map((item, index) => {
          const config = { showFormatTitle: true, collectionTitle: collection.title, position: index + 1 };
          if (item.type === PROGRAM.CLIP) return new ClipModel(item, config);
          if (item.type === PROGRAM.EPISODE) return new EpisodeModel(item, config);
          return null;
        })
        .filter(item => item) as VideoModel[];

      items.forEach(item => {
        item.eventValues.category = TEALIUM_EVENT_CATEGORY.PLAYER;
        item.eventValues.name = TEALIUM_EVENT_NAME.SIDEBAR_CLICK;
        // todo: check value of label
      });
      setSideBarVideos(items);
    }
  });

  const [screenWidth, screenHeight] = useScreenDimensions();

  const playerWidth = IS_CLIENT ? getPlayerWidth(screenWidth, screenHeight) : null;

  const VideoTime: string = playlistItem.media?.[0]?.airedDateTime
    ? getSocialDate(playlistItem.media[0].airedDateTime)
    : playlistItem.media?.[0]?.availableDate
    ? getSocialDate(Number.parseInt(playlistItem.media[0].availableDate, 10))
    : getSocialDate(playlistItem.added as number);

  const imageMediaId: string = playlistItem?.imageLandscape?.src;

  const loading = enabled === null || loadingSidebar;
  if (loading) {
    return (
      <>
        <SkeletonVideoHeading hasSidebar={!!enabled} hideMetadata />
        <VideoMetaDataWrapperStyle data-testid="videoMetaDataWrapperStyle" headingWidth={playerWidth}>
          <VideoMetaData
            videoTime={VideoTime}
            label=""
            pageType={pageType || ''}
            video={playlistItem}
            viewMode={viewMode}
          />
        </VideoMetaDataWrapperStyle>
      </>
    );
  }

  return (
    <VideoHeadingStyle data-testid="videoHeadingLayout" hasSidebar={!!sideBarVideos.length}>
      <VideoHeadingInnerStyle>
        <PlayerWrapperStyle>
          {availabilityState ? (
            <PlayerContainer
              client={client}
              playlistItem={playlistItem}
              viewMode={viewMode}
              ratings={playlistItem.ratings}
              collectionSlug={collectionSlug}
              availableSeasons={availableSeasons}
            />
          ) : (
            <VideoHeadingImageInnerStyle>
              {playlistItem?.labelText && (
                <TextLabelStyle suppressHydrationWarning>{playlistItem?.labelText}</TextLabelStyle>
              )}
              <ImageMedia
                publicId={imageMediaId}
                imageMedia={{ type: IMAGE_MEDIA_TYPE.REMOTE }}
                responsive
                shouldLazyLoad
                transformations={{
                  width: playerWidth || 800,
                  crop: 'fill',
                  gravity: 'faces:auto',
                  fetch_format: 'auto',
                  aspect_ratio: '16:9'
                }}
              />
            </VideoHeadingImageInnerStyle>
          )}
        </PlayerWrapperStyle>
        {data?.collection && !!sideBarVideos.length && (
          <PlayerSidebar title={data.collection.title} videos={sideBarVideos} currentVideo={playlistItem.guid} />
        )}
      </VideoHeadingInnerStyle>
      <VideoHeadingSeparatorStyle />
      <VideoMetaDataWrapperStyle data-testid="videoMetaDataWrapperStyle" headingWidth={playerWidth}>
        <ErrorBoundary
          onError={
            /* istanbul ignore next */
            _ => <CommonFallback message={FALLBACK_UI_LABELS.VIDEO_META_DATA} />
          }
        >
          <VideoMetaData
            videoTime={VideoTime}
            label=""
            pageType={pageType || ''}
            video={playlistItem}
            viewMode={viewMode}
          />
        </ErrorBoundary>
      </VideoMetaDataWrapperStyle>
    </VideoHeadingStyle>
  );
};

export default VideoHeadingLayout;
