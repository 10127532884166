import React, { FunctionComponent, useEffect, useState } from 'react';
import { IPlayerSidebarProps } from 'typings/IPlayerSidebarProps';

import VideoList from 'components/VideoList';
import { useScreenDimensions } from 'lib/useScreenDimensions';
import { PlayerSidebarStyle, PlayerSidebarTitleStyle, SidebarVideoListStyle } from './PlayerSIdebar.style';

const titleHeight = 70;
const sidebarItemHeight = 90;
const calculateNoOfVideos = (height: number): number => Math.floor((height - titleHeight) / sidebarItemHeight);

const PlayerSidebar: FunctionComponent<IPlayerSidebarProps> = ({ title, videos, currentVideo }): JSX.Element | null => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [noOfVideos, setNoOfVideos] = useState(0);
  const [width] = useScreenDimensions(10);

  useEffect(() => {
    if (!ref.current) return;
    const { clientHeight } = ref.current;
    setNoOfVideos(calculateNoOfVideos(clientHeight));
  }, [width, ref]);

  const programList = React.useMemo(
    () => videos.filter(item => item.guid !== currentVideo).slice(0, noOfVideos),
    [videos, currentVideo, noOfVideos]
  );

  return (
    <PlayerSidebarStyle ref={ref}>
      {programList.length ? (
        <>
          <PlayerSidebarTitleStyle>{title}</PlayerSidebarTitleStyle>
          <SidebarVideoListStyle>
            <VideoList smLandscape programList={programList} isPlayerSideBar />
          </SidebarVideoListStyle>
        </>
      ) : null}
    </PlayerSidebarStyle>
  );
};

export default PlayerSidebar;
