import { FunctionComponent, RefObject, useRef, useState } from 'react';
import { IVideoMetaDataProps } from 'typings/IVideoMetaDataProps';
import VideoMetaDataLayout from './VIdeoMetaDataLayout';
import { VideoMetaDataStyle } from './VideoMetaData.style';

const VideoMetaData: FunctionComponent<IVideoMetaDataProps> = (props): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const toggleElement: RefObject<any> = useRef(null);
  const toggleArrow: RefObject<any> = useRef(null);

  const collapseMetadata = (): void => {
    const collapseElementHeight = toggleElement.current.scrollHeight;
    const collapseElementTransition = toggleElement.current.style.transition;
    toggleElement.current.style.transition = '';

    toggleArrow.current.style.transform = 'rotate(90deg)';

    requestAnimationFrame(() => {
      toggleElement.current.style.height = `${collapseElementHeight}px`;
      toggleElement.current.style.transition = collapseElementTransition;

      requestAnimationFrame(() => {
        toggleElement.current.style.height = '0px';
        toggleElement.current.style.transition = collapseElementTransition;
      });
    });
    setIsCollapsed(true);
  };

  const expandMetadata = (): void => {
    const collapseElementHeight = toggleElement.current.scrollHeight;

    toggleElement.current.style.height = `${collapseElementHeight}px`;
    toggleArrow.current.style.transform = 'rotate(-90deg)';

    setIsCollapsed(false);
  };

  const toggleVideoDescription = (): void => {
    isCollapsed ? expandMetadata() : collapseMetadata();
  };

  return (
    <VideoMetaDataStyle data-testid="videoMetaData">
      <VideoMetaDataLayout
        viewMode={props.viewMode}
        video={props.video}
        label={props.label}
        videoTime={props.videoTime}
        isCollapsed={isCollapsed}
        toggleVideoDescription={toggleVideoDescription}
        toggleRef={toggleElement}
        toggleArrowRef={toggleArrow}
      />
    </VideoMetaDataStyle>
  );
};

export default VideoMetaData;
