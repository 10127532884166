import React, { FunctionComponent } from 'react';
import ProgramsByGuidQuery from 'queries/programs/programsByGuidQuery.query';
import { SourcesUncachedQuery } from 'queries/programs/sources.query';
import ClipModel from 'models/clip.program.model';
import EpisodeModel from 'models/episode.program.model';
import MovieModel from 'models/movie.program.model';
import VideoModel from 'models/video.model';
import { IVideoHeadingProps } from 'typings/IVideoHeadingProps';
import useFeature from 'hooks/useFeature';
import { useRouter } from 'next/router';
import useImprovedQuery from 'hooks/useImprovedQuery';
import VideoUnavailable from 'components/PlayerContainer/VideoUnavailable/VideoUnavailable';
import { PROGRAM, PAGE, FEATURE_SLUG, PLAYER_MESSAGES } from '../../constants';
import { SkeletonVideoHeading } from '../SkeletonElement/SkeletonElement';
import VideoHeadingLayout from './VideoHeadingLayout';
import { VideoHeadingErrorStyle } from './VideoHeadingLayout/VideoHeadingLayout.style';

const VideoHeading: FunctionComponent<IVideoHeadingProps> = ({
  videoType,
  videoGuid,
  client,
  viewMode,
  availableSeasons
}) => {
  const router = useRouter();
  const isMovie = videoType === PROGRAM.MOVIE;

  const { tab, afspeellijst } = router.query || {};
  const collectionSlug = tab || afspeellijst;

  const { error, processedData: video } = useImprovedQuery<
    {
      programs: { items: IProgramGraphql[]; totalResults: number };
    },
    VideoModel
  >(ProgramsByGuidQuery, {
    variables: { guid: videoGuid },
    processData: data => {
      const { items } = data.programs;

      if (!items.length) return null;

      const videoToPlay = items[0];

      if (isMovie) {
        return new MovieModel(videoToPlay);
      }

      return videoToPlay.type === PROGRAM.CLIP ? new ClipModel(videoToPlay) : new EpisodeModel(videoToPlay);
    }
  });

  const { loading: sourcesLoading, processedData: uncachedVideo } = useImprovedQuery<
    {
      programs: { items: { guid: string; sources: ISourceGraphql[] }[] };
    },
    VideoModel
  >(SourcesUncachedQuery, {
    variables: { guid: video?.guid },
    // Only execute query when program is not available but when availableDate is in the past
    skip: !(video?.isAvailable === false && !!video.availableDate && new Date().getTime() >= video.availableDate),
    pollInterval: 10000,
    notifyOnNetworkStatusChange: true,
    processData: data => {
      const [program] = data.programs.items;

      if (!program?.sources.length) return null;

      // remove null DRM fields
      const sources = program.sources.map(source => {
        const drmKey = source.drm && Object.keys(source.drm).find(type => source.drm && source.drm[type] !== null);
        if (drmKey && source.drm) {
          return { ...source, drm: { [drmKey]: source.drm[drmKey] } };
        }
        return source;
      });

      return { ...video, sources, isAvailable: true } as VideoModel;
    }
  });

  const { enabled: hasSidebar } = useFeature(FEATURE_SLUG.PLAYER_SIDEBAR);

  if (error) {
    return (
      <VideoHeadingErrorStyle>
        <VideoUnavailable {...PLAYER_MESSAGES.NO_PLAYABLE_SOURCES_FOUND} />
      </VideoHeadingErrorStyle>
    );
  }

  if (sourcesLoading || !video) {
    return <SkeletonVideoHeading hasSidebar={!!hasSidebar} />;
  }

  const playlistItem = uncachedVideo || video;

  return (
    <div data-testid="videoHeadingProgram">
      <VideoHeadingLayout
        client={client}
        viewMode={viewMode}
        playlistItem={playlistItem}
        pageType={isMovie ? PAGE.MOVIE : PAGE.PROGRAM}
        availabilityState={playlistItem.isAvailable}
        collectionSlug={collectionSlug}
        availableSeasons={availableSeasons}
      />
    </div>
  );
};

export default VideoHeading;
