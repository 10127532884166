import { useEffect } from 'react';
import { BodyText, Heading1 } from 'components/Text/Text.style';
import {
  VideoAdBlockerContainerStyle,
  VideoAdBlockerMessageStyle,
  VideoAdBlockerIconStyle,
  VideoAdBlockerInnerIconStyle
} from 'components/PlayerContainer/VideoAdBlockEnabled/VideoAdBlockEnabled.style';
import Button from 'components/Button';
import { IS_CLIENT } from '../../../constants';

const VideoAdBlockEnabled: React.FC = () => {
  useEffect(() => {
    if (IS_CLIENT && window.newrelic) {
      window.newrelic.noticeError(new Error('Adblock warning shown'));
    }
  }, []);

  return (
    <VideoAdBlockerContainerStyle data-testid="video-ad-block">
      <VideoAdBlockerMessageStyle>
        <VideoAdBlockerIconStyle>
          <VideoAdBlockerInnerIconStyle>!</VideoAdBlockerInnerIconStyle>
        </VideoAdBlockerIconStyle>
        <Heading1>Fijn dat je KIJK wil gaan gebruiken</Heading1>
        <BodyText>
          Je gebruikt een ad blocker, zet deze uit voor kijk.nl om alle programma's en films gratis te zien
        </BodyText>
        <Button onClick={() => window.location.reload()}>Ik heb de ad blocker uitgezet voor KIJK.nl</Button>
      </VideoAdBlockerMessageStyle>
    </VideoAdBlockerContainerStyle>
  );
};

export default VideoAdBlockEnabled;
