import styled, { css } from 'styled-components';

export const AdWrapperStyle = styled.div<{ noOfItemsInRow?: number }>`
  grid-column: ${props => `1 / span  ${props.noOfItemsInRow || 1}`};
  position: relative;
  z-index: 1;
`;

const h1Styles = css`
  padding: 2px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.mdMin}) {
    padding: 42px 0 0;
  }
`;

const h1StylesMovies = css`
  padding: 15px 0 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.mdMin}) {
    padding: 30px 0 42px;
  }
`;

export const AdContentStyle = styled.div<{ page?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${({ id }) => (id === 'h1' || id === 'r1') && h1Styles}

  * {
    max-width: 100%;
  }

  > :first-child {
    display: inline-block;
  }

  &:empty {
    position: absolute !important;
    opacity: 0;
    visibility: hidden;
  }

  ${({ id, page }) => page === 'movies' && (id === 'h1' || id === 'r1') && h1StylesMovies}
`;
