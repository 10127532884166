import { VideoPlaceholderContainerStyle, VideoPlaceholderPlayIconStyle } from './VideoPlaceholder.styles';

const VideoPlaceholder = ({ handleClick, image }: IVideoPlaceholderProps) => (
  <VideoPlaceholderContainerStyle
    onClick={handleClick}
    role="button"
    tabIndex={0}
    aria-label="Play"
    backgroundUrl={`url(${image})`}
  >
    <VideoPlaceholderPlayIconStyle onClick={handleClick} role="button" tabIndex={0} aria-label="Play" />
  </VideoPlaceholderContainerStyle>
);

export default VideoPlaceholder;
