import React, { memo, FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AdWrapperStyle, AdContentStyle } from './Ad.style';

const Ad: FC<IAdProps> = ({ id, page, noOfItemsInRow }: IAdProps) => {
  const [key, setKey] = useState(uuidv4());

  const trackViewHandler = () => {
    setKey(uuidv4());
  };

  useEffect(() => {
    // Empty ad on Taq track view
    window.addEventListener('track-view', trackViewHandler);

    return () => {
      window.removeEventListener('track-view', trackViewHandler);
    };
  }, []);

  return (
    <AdWrapperStyle data-testid="ad" key={key} noOfItemsInRow={noOfItemsInRow}>
      <AdContentStyle id={id} page={page} />
    </AdWrapperStyle>
  );
};

export default memo(Ad);
