import SVG from 'react-inlinesvg';
import Button from 'components/Button/Button';
import { Heading1, BodyText } from '../../Text/Text.style';
import { VideoUnavailableContainerStyle, VideoUnavailableMessageStyle } from './VideoUnavailable.styles';

const RELOAD_BUTTON_TEXT = 'Laad opnieuw';

const VideoUnavailable = ({ handleClick, icon, heading, body, hasLargeHeading }: IVideoUnavailableProps) => (
  <VideoUnavailableContainerStyle>
    <VideoUnavailableMessageStyle hasLargeHeading={!!hasLargeHeading}>
      {icon && <SVG src={icon} />}
      <Heading1>{heading}</Heading1>
      <BodyText>{body}</BodyText>
      <Button onClick={handleClick || (() => window.location.reload())} isActionButton>
        {RELOAD_BUTTON_TEXT}
      </Button>
    </VideoUnavailableMessageStyle>
  </VideoUnavailableContainerStyle>
);

export default VideoUnavailable;
