import { useEffect, useState } from 'react';

import VideoAdBlockEnabled from 'components/PlayerContainer/VideoAdBlockEnabled';
import isSearchBot from 'lib/isSearchBot';
import { IPlayerContainerProps } from 'typings/IPlayerContainerProps';
import { detectAdBlock } from 'lib/adBlockDetection';
import featureTooling from 'components/FeatureTooling/FeatureTooling';
import { SkeletonPlayer } from 'components/SkeletonElement/SkeletonElement';
import PlayerContainer from './PlayerContainer';
import { FEATURE_SLUG, IS_CLIENT } from '../../constants';

const searchBot = IS_CLIENT && isSearchBot();

const STATE = {
  INITIAL: 'initial',
  SHOW: 'show',
  ADBLOCK: 'adblock'
};

const AdblockProtectedPlayer: React.FC<IPlayerContainerProps> = props => {
  const [state, setState] = useState(STATE.INITIAL);
  useEffect(() => {
    featureTooling.waitUntilReady().then(async () => {
      if (
        document.cookie.includes('__ALLOW_ADBLOCK__') ||
        !featureTooling.isFeatureEnabled(FEATURE_SLUG.ADBLOCK_DETECTION)
      ) {
        setState(STATE.SHOW);
        return;
      }

      const detected = await detectAdBlock();
      setState(detected ? STATE.ADBLOCK : STATE.SHOW);
    });
  }, []);

  if (state === STATE.SHOW || searchBot || !IS_CLIENT) {
    return <PlayerContainer {...props} />;
  }

  if (state === STATE.ADBLOCK) {
    return <VideoAdBlockEnabled />;
  }

  return <SkeletonPlayer />;
};

export default AdblockProtectedPlayer;
